<template>
    <div class="scan">
        <div class="check-product-camera">
            <p class="ma-0 white--text text-body-1 text-center scanner-explanation" :style="{top: `${componentHeaderHeight}px`}">{{$t("checkProductExplain", {nbScannedProducts, orderNbProducts})}}</p>
            <ScannerCamera />
        </div>
        <div class="white py-3 check-product-recap full-height">
            <div class="d-flex align-center justify-center check-product-recap-header">
                <div :class="{'px-2 py-1 rounded-tl-pill rounded-bl-pill text-caption scan-type-selector text-center': true, 'primary white--text': completeScan}" style="width: 70px" @click="completeScan = true"> Complet </div>
                <div :class="{'px-2 py-1 rounded-tr-pill rounded-br-pill text-caption scan-type-selector text-center': true, 'primary white--text': !completeScan}" style="width: 70px" @click="completeScan = false"> Partiel </div>
            </div>

            <div class="pb-1 check-product-recap-body">
                <div class="mt-4 px-3 rounded-lg elevation-2 mx-3 d-flex flex-column align-center">
                    <template v-for="(product, key) in orderToCheckClone.basketProducts">
                        <CheckProductBloc :key="`check-product-${product.key}`" :productToCheck="product" />
                        <v-divider v-if="key !=  orderToCheckClone.basketProducts.length - 1" class="mx-3 full-width"
                            :key="`check-product-div-${key}`" />
                    </template>
                </div>
            </div>
            <div class="white px-4 py-3 d-flex justify-center align-center check-product-footer">
                <v-btn class="full-width rounded-pill py-5" elevation="0" dark color="primary" @click="handleFinishCheck()"> {{$t(buttonText)}} </v-btn>
            </div>
            <v-dialog v-model="dialog" persistent>
                <ProductsCheckValidationDialog :key="`products-check-validation-dialog-${dialog}`" :dialogTitleKey="dialogTitleKey" :dialogBodyKey="dialogBodyKey"/>
            </v-dialog>
            <audio id="beep" ref="beep" controls hidden>
                <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
                {{$t('errorSound')}}
            </audio>
        </div>

    </div>

</template>

<script>
    import ScannerCamera from "@/components/subcomponents/ScannerCamera.vue";
    import CheckProductBloc from "@/components/CheckProductBloc.vue";
    import ProductsCheckValidationDialog from "@/components/ProductsCheckValidationDialog.vue";
    import {mapActions, mapState} from 'vuex'
    const Barcode = require("@/assets/js/Barcode");

    export default {
        name: 'CheckProducts',
        components: {
            ScannerCamera,
            CheckProductBloc,
            ProductsCheckValidationDialog
        },
        data() {
            return {
                basket: null,
                scannedProducts: [],
                cameras: [],
                cameraToUse: null,
                error: false,
                completeScan: true,
                orderToCheckClone: null,
                dialog: false,
                dialogTitleKey: "",
                dialogBodyKey: "",
                componentHeaderHeight: 0
            }
        },
        computed: {
            ...mapState('header', ['currentPage']),
            orderNbProducts() {
                return this.orderToCheckClone.basketProducts.reduce((acc, basketProduct) => basketProduct.qty + acc, 0)
            },
            nbScannedProducts() {
                return this.scannedProducts.length
            },
            nbTimesScanned() {
                return product => {
                    let productAlreadyScanned = this.scannedProducts.filter(scannedEan => product.ean == scannedEan)
                    if (productAlreadyScanned) {
                        return productAlreadyScanned.length
                    }
                    return 0
                }
            },
            buttonText() {
                return this.completeScan ? "checkProductFullFinishBtnLabel" : "checkProductPartialFinishBtnLabel"
            }
        },
        mounted () {
            Barcode.start(this.onDecode, "landscape")
        },
        created() {
            this.changeCurrentPage(this.$route.meta.page)
            this.orderToCheckClone = JSON.parse(JSON.stringify(this.orderToCheck))
            this.orderToCheckClone.basketProducts = this.orderToCheckClone.basketProducts.map(basketProduct => Object
                .assign(basketProduct, {
                    key: this.generateRandom(),
                    nbTimesScanned: 0
                }))
            // this.getCameras()
        },
        methods: {
            ...mapActions('header', [ 'changeCurrentPage' ]),
            generateRandom() {
                return parseInt(Math.random() * 10000)
            },
            // async getCameras() {
            //     this.cameras = await Barcode.getCameras()
            // },

            onDecode(decodedString) {
                console.log("Scan products")
                let productEan = decodedString.codeResult.code
                this.checkProduct(productEan)
                this.$refs.beep.play();
            },

            checkProduct(ean) {
                let productFound = this.orderToCheckClone.basketProducts.find(basketProduct => basketProduct.product
                    .ean === ean)
                if (productFound) {
                    let productAlreadyScanned = this.scannedProducts.filter(scannedEan => ean == scannedEan)
                    if (!productAlreadyScanned || (productAlreadyScanned && productAlreadyScanned.length < productFound
                            .qty)) {
                        productFound.nbTimesScanned = productAlreadyScanned ? productAlreadyScanned.length + 1 : 0
                        productFound.key = this.generateRandom()
                        this.scannedProducts.push(ean)
                    }
                }
            },

            handleFinishCheck() {
                if (this.completeScan) {
                    this.dialogTitleKey = "orderCompleteCheckValidationDialogTitle"
                    this.dialogBodyKey = "orderCompleteCheckValidationDialogBody"
                } else {
                    this.dialogTitleKey = "orderPartialCheckValidationDialogTitle"
                    this.dialogBodyKey = "orderPartialCheckValidationDialogBody"
                }
                this.dialog = true
            },
            calculateHeaderHeight(){
                return document.getElementById("app-header").offsetHeight + 48 || 0
            }
        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            Barcode.reset()
            next();
        },
        watch: {
            cameras() {
                Barcode.reset()
                this.cameraToUse = this.cameras[0]
            },
            cameraToUse(camera) {
                Barcode.reset()
                Barcode.start(camera.device, this.onDecode);
            },
            nbScannedProducts(){
                if(this.nbScannedProducts === this.orderNbProducts){
                    this.handleFinishCheck()
                }
            },
            currentPage(){
                this.componentHeaderHeight = this.calculateHeaderHeight();
            }
        }

    };
</script>
<style scoped>
    .scan {
        background: black;
        position: relative;
        height: calc(var(--vh, 1vh) * 100);
        display: grid;
        grid-template-rows: repeat(2, 50%);
        overflow: hidden;
    }

    .check-product-camera {
        height: 100%;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
    }

    .target-camera {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .scanner-explanation {
        position: absolute;
        width: 100%;
        z-index: 10;
    }

    .scan-type-selector {
        border: 1px solid var(--color-primary);
    }

    .check-product-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .check-product-recap-body {
        max-height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .check-product-recap {
        display: grid;
        grid-template-rows: 60px auto 60px;
    }

    .outlined-btn {
        border: solid 2px var(--color-primary) !important;
    }
</style>
