<template>
    <div class="product-check-validation-dialog">
        <template v-if="!validationComplete">
            <v-card class="d-flex flex-column">
                <v-card-title class="font-weight-bold primary--text text-button"> {{$t(dialogTitleKey)}} </v-card-title>
                <v-card-text class="primary--text text-body-2 my-4">{{$t(dialogBodyKey)}}</v-card-text>
                <v-divider class="align-self-center mx-3 mt-4 full-width primary" inset />
                <v-card-actions class="align-self-center my-2">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="button-outline-blue font-weight-bold outlined-btn px-6" outlined text @click="handleNo()" small> {{$t('orderValidationDialogNoLabel')}} </v-btn>
                    <v-btn class="font-weight-bold rounded-lg py-1 px-6" color="primary" @click="handleYes()"
                        small> {{$t('orderValidationDialogYesLabel')}} </v-btn>
                </v-card-actions>
            </v-card>
        </template>
        <template v-else>
            <OrderValidationStatusDialog :validOrder="validOrder" />
        </template>
    </div>
</template>

<script>
    import OrderValidationStatusDialog from "@/components/OrderValidationStatusDialog"
    export default {
        name: "ProductsCheckValidationDialog",
        components: { OrderValidationStatusDialog },
        props: {
            dialogTitleKey: String,
            dialogBodyKey: String
        },
        data() {
            return {
                validOrder: false,
                validationComplete: false
            }
        },
        methods: {
            handleYes() {
                this.validOrder = true
                this.validationComplete = true
                setTimeout(() => {
                    this.$router.push({ name: "Home" })
                }, 6000);
            },
            handleNo() {
                this.validOrder = false
                this.validationComplete = true
                setTimeout(() => {
                    this.$router.push({ name: "Home" })
                }, 6000);
            }
        }
    }
</script>

<style lang="scss">
</style>
