<template>
    <div :class="{'mx-2 pa-2 d-flex align-center justify-space-between full-width check-product-wrapper': true, 'product-checked': productToCheck.qty == productToCheck.nbTimesScanned }">
        <v-badge v-if="productToCheck.qty > 1 && productToCheck.qty - productToCheck.nbTimesScanned > 0" bordered top :content="productToCheck.qty - productToCheck.nbTimesScanned " color="mint"  offset-y="20" >
            <v-avatar size="55" tile>
                <v-img :src="productImgUrl" contain></v-img>
            </v-avatar>
        </v-badge>
        <v-avatar v-else size="55" class="custom-avatar">
            <v-img :src="productImgUrl" contain></v-img>
        </v-avatar>
        <p :class="{'ma-0 text-caption': true, 'text-decoration-line-through': productToCheck.qty == productToCheck.nbTimesScanned}">{{translateName(productToCheck.product.name) | capitalizeFirstLetter}}</p>
        <p class="ma-0 text-caption font-weight-bold">{{formatPrice(productToCheck.product.price)}}</p>
    </div>
</template>

<script>
    export default {
        name: "CheckProductBloc",
        props: {
            productToCheck: {
                type: Object
            }
        },
        computed: {
            productImgUrl() {
                return this.config.WS_URL + "/catalog/product/image/" + this.productToCheck.product.image;
            }
        },
        watch:{
            productToCheck(){
                console.log(this.productToCheck.qty - this.productToCheck.nbTimesScanned)
            }
        }
    }
</script>

<style scoped>
    .product-checked{
        opacity: 0.5;
    }
    .custom-avatar{
        box-shadow: 0px 2px 2px #0000000d;
        border-radius: 12px;
    }
</style>
