<template>
    <v-card class="d-flex flex-column align-center">
        <v-card-title class="font-weight-bold primary--text text-body-1 pb-2">
            {{$t("orderValidationCompleteDialogTitle")}} </v-card-title>
        <v-card-text class="d-flex justify-center primary--text text-body-2 pb-0">
            <v-avatar size="50">
                <img v-if="validOrder" :src="require(`@/assets/img/icon-check.png`)">
                <img v-else :src="require(`@/assets/img/icon-cross.png`)">
            </v-avatar>
        </v-card-text>
        <v-card-text :class="['d-flex justify-center font-weight-bold text-h6 pt-2', validOrder ? 'customGreen--text': 'customRed--text' ]">
            {{$t(validOrder ? 'orderValidationCompleteDialogValid': 'orderValidationCompleteDialogNonValid')}}
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        props: {
            validOrder: {
                type: Boolean
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
